@import "../../../../styles/classes";

.loginWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;

  &-image {
    object-fit: contain;
  }

  .styledInput {
    margin-bottom: 20px;
  }

  &-submitBtn {
    @extend .defaultSubmitBtn;
    margin-bottom: 0;
    width: unset;
    height: 38px;
  }

  &-errorMessage {
    color: $red-color;
    margin: 0 0 20px;
  }
}
