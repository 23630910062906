@import "../../../../../../styles/variables";
$dCD: '.dashboardCompanyDescription';

#{$dCD} {
  padding: 120px 0;
  position: relative;

  > div:not(:last-child):not(:first-child) {
    margin-bottom: 20px;
  }

  .companyDescriptionWrapper {
    padding-top: 0;
  }
}
