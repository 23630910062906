@import '../../../../../styles/variables.scss';

.message{
    padding: 15px;
    width: 74%;
    top: 10vh;
    left: 12%;
    background: white;
    position: inherit;
    z-index: 10;
    text-align: left;
    text-transform: none;
    .close{
        cursor: pointer;
        padding: 5px;
        text-align: right;
        width: 15px;
        position: absolute;
        right: 10px;
    }
    .secondary{
        color: $brown-color;
    }
    &-content{
        padding:15px 0;
        overflow: auto;
        max-height: 50vh;
    }
}