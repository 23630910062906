@import "../../../../../styles/variables";

.vacancyPreview {
  display: inline-block;
  height: 270px;
  transition: width 0.35s ease, box-shadow 0.35s ease;
  padding: 25px 15px 5px;
  box-shadow: inset 0 0 0 6px $blue-color;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &.active {
    box-shadow: inset 0 0 0 6px $dark-grey-color;
  }

  &-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(34, 34, 34, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4%;
    pointer-events: none;
    transition: all 0.4s ease;
    transform: translateY(-100%);
    margin: 0;
    font: 500 18px/1.4 "Roboto", sans-serif;
    color: $white-color;
  }

  &:hover {
    .vacancyPreview-view {
      transform: translateY(0);
    }
  }

  &-icon {
    height: 100px;
    width: auto;
    object-fit: contain;
    margin-bottom: 20px;
  }

  &-title {
    text-align: center;
    font: 400 21px/1.3 "Roboto", sans-serif;
    color: $black-color;
    margin: 0;
    text-transform: uppercase;
    padding-bottom: 0.66667rem;
    height: 5em;
  }
}
