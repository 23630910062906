@import "../../../styles/variables.scss";

.background {
    position: fixed;
    width: 100vw;
    z-index: 5;
    left: 0;
    height: 100vh;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    .success {
        background: white;
        color: $blue-color;
    }
    .warning {
        background: $orange-color;
        color: $black-color
    }
    .error {
        background: $red-color;
        color: $black-color
    }
    &-content {
        height: 25%;
        width: 50%;
        overflow: auto;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
    }
    &-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2.1em;
        text-align: center;
    }
}
