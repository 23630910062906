.service {
  display: flex;
  flex-direction: column;
  text-align: center;

  &-image {
    height: 130px;
    margin: 0 auto 1rem;
  }

  &-title {
    font-size: 19px;
    padding-bottom: 10px;
    margin: 0;
  }

  &-text {
    padding: 0 3px;
    margin: 0;
  }
}
