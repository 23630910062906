@import '../../../../styles/variables';

.inTouch {
  &-descriptionWrapper {
    display: flex;
    align-items: center;
  }

  &-description {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin-right: 3%;

    &-name,
    &-position {
      margin: 0;
      text-transform: capitalize;
    }

    &-name {
      margin-bottom: 10px;
      font: 908 24px/1.3 'Roboto', sans-serif;
      color: $blue-color;
    }

    &-position {
      font: 400 21px/1.3 'Roboto', sans-serif;
      color: $black-color;
      margin-bottom: 2rem;
      padding: 0;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-item {
      text-decoration: none;
      color: $black-color;
      font-size: 18px;
      padding: 3px 0;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      outline: none;
      position: relative;

      &:before {
        order: 1;
        font-size: 22px;
        margin-left: 20px;
        width: 22px;
        z-index: 2;
        background-color: transparent;
        color: $black-color;
        transition: color 0.35s ease;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(100% - 28px);
        height: 0;
        background: #000;
        z-index: -1;
        width: 32px;
        transition: height 0.35s ease;
      }

      &:hover {
        &:before {
          color: $white-color;
        }
        &:after {
          height: 100%;
        }
      }
    }
  }

  .multipleItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    &-person {
      width: 25%;
      .inTouch-description-position {
        height: 55px;
      }
      .inTouch-links {
        align-items: flex-start;
        &-item {
          flex-direction: row-reverse;
        }
        &-item:before {
          margin-left: 0;
          margin-right: 20px;
        }
        &-item:after {
          right: calc(100% - 28px);
          left: -5px;
        }
      }
    }
  }

  &-imgWrapper {
    flex: 1;
    align-items: flex-start;

    &-img {
      max-width: 314px;
      height: auto;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 768px) {
  .inTouch {
    &-descriptionWrapper {
      flex-direction: column;
    }
    &-description {
      margin-bottom: 2rem;
    }
    .multipleItems {
      &-person {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .inTouch {
    .multipleItems {
      &-person {
        .inTouch-description-position {
          height: auto;
        }
        width: 100%;
        text-align: center;
        .inTouch-links {
          text-align: left;
        }
      }
      &-person:nth-child(2n) {
        margin-top: 50px;
      }
    }
  }
}
