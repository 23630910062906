@import "../../../../styles/functions";

.offer {
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5% 0 0;
    justify-content: center;
  }

  &-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 0 calc(20% - 1.5%);
    margin: 0 1.5% 1.8em 0;
    &-image {
      max-width: 100px;
    }
    &-text {
      font: 500 18px/1.4 "Roboto", sans-serif;
      text-align: center;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .offer {
    &-item {
      flex: 0 0 calc(25% - 1.5%);
    }
  }
}


@media screen and (max-width: 860px) {
  .offer {
    &-item {
      flex: 0 0 calc(33.3% - 1.5%);
    }
  }
}

@media screen and (max-width: 568px) {
  .offer {
    &-item {
      flex: 0 0 calc(50% - 1.5%);
    }
  }
}

@media screen and (max-width: 400px) {
  .offer {
    &-item {
      flex: 0 0 calc(100% - 1.5%);
    }
  }
}
