.technologies {
  &-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.technology {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-basis: 200px;
  flex-grow: 0.5;
  max-width: 250px;

  &-image {
    height: 130px;
    margin: 0 auto 1rem;
  }

  &-title {
    font-size: 19px;
    padding-bottom: 10px;
    margin: 0;
  }

  &-text {
    padding: 0 3px;
    margin: 0;
  }
}
