@import "../../../styles/variables";

.contact {
  text-decoration: none;
  outline: none;
  color: $black-color;
  font-weight: 500;
  padding-left: 40px;
  position: relative;

  &:before {
    position: absolute;
    top: 1px;
    left: 0px;
    font-size: 28px;
    color: $dark-grey-color;
  }
}
