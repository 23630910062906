@import 'fonts';
@import 'variables';
@import 'icons';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-quill/dist/quill.snow.css';

* {
  &::selection {
    background-color: $blue-color !important;
    color: $white-color !important;
  }
}

html {
  font: 400 30px/1.6 'Roboto', sans-serif;
  background: $white-color;
  min-height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  font-size: 16px;
  ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;

  .H_l_bottom {
    display: none;
  }

  .H_l_anchor {
    margin-top: 55px !important;
  }

  .H_btn[title='Zoom in'] {
    background-color: inherit;
    background-image: url('/icons/plus.svg');
    width: 30px;
    padding: 0;

    > svg {
      visibility: hidden;
    }
  }

  .H_btn[title='Zoom out'] {
    padding: 0;
    background-color: inherit;
    background-image: url('/icons/minus.svg');
    width: 30px;

    > svg {
      visibility: hidden;
    }
  }
}

h3 {
  font: 400 21px/1.3 'Roboto', sans-serif;
  color: $black-color;
  text-transform: uppercase;
  padding-bottom: 0.66667rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.slick-slide {
  padding: 0 5px;
  box-sizing: border-box;
}

.ql-container {
  font-family: unset;
}

.ql-picker-options {
  max-height: 195px;
  overflow: scroll;
}

.ql-snow {
  .ql-picker {
    &.ql-font {
      .ql-picker-item[data-value='Arial']::before,
      .ql-picker-label[data-value='Arial']::before {
        content: 'Arial';
        font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
      }

      .ql-picker-item[data-value='Helvetica']::before,
      .ql-picker-label[data-value='Helvetica']::before {
        content: 'Helvetica';
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }

      .ql-picker-item[data-value='Agency FB']::before,
      .ql-picker-label[data-value='Agency FB']::before {
        content: 'Agency FB';
        font-family: 'Agency FB', sans-serif;
      }

      .ql-picker-item[data-value='Roboto']::before,
      .ql-picker-label[data-value='Roboto']::before {
        content: 'Roboto';
        font-family: 'Roboto', sans-serif;
      }
    }
    &.ql-size {
      $i: 72;
      @while $i > 0 {
        .ql-picker-item[data-value='#{$i}px']::before,
        .ql-picker-label[data-value='#{$i}px']::before {
          content: '#{$i}px';
        }
        $i: $i - 2;
      }
    }
  }
}

.ql-toolbar * {
  outline: none;
}

.editIcons {
  width: 24px;
  height: 24px;
  background: #000000;
  position: relative;
  cursor: pointer;
  margin: 0;

  &:before,
  &:after {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    background: $white-color;
  }

  &-add {
    &:before,
    &:after {
      width: 2px;
      height: 12px;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &-delete {
    &:before,
    &:after {
      width: 2px;
      height: 14px;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &-edit {
    background: $blue-color;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
      display: none;
    }
  }
}

.animation_opacity_start {
  opacity: 1;
  transition: opacity 2s ease;
}

.animation_opacity_end {
  opacity: 0;
  transition: none;
}

.rotate-in-hor_start {
  -webkit-animation: rotate-in-hor 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-in-hor 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  opacity: 1;
}

.rotate-in-hor_end {
  -webkit-animation: none;
  animation: none;
  opacity: 0;
}

.flip-in-hor-top_start {
  -webkit-animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  opacity: 1;
}

.flip-in-hor-top_end {
  -webkit-animation: none;
  animation: none;
  opacity: 0;
}

@media screen and (max-width: 1151px) {
  html {
    font-size: 27px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 24px;
  }
  .slick-slider {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 21px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 18px;
  }
}

@-webkit-keyframes rotate-in-hor {
  0% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes rotate-in-hor {
  0% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
