@import "../../../styles/variables";

.address {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  position: relative;

  &:before {
    position: absolute;
    top: 1px;
    left: -15px;
    font-size: 28px;
    color: $dark-grey-color;
  }

  &-title, &-country, &-address, &-city {
    font-weight: 500;
    white-space: pre-line;
    margin: 0;
  }

  &-address, &-city {
    color: $grey-color;
  }
}
