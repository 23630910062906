@import '../../../../styles/variables';

.companyDescriptionWrapper {
  width: 735px;
  margin: 0 auto;
  max-width: 90%;
  padding-top: 280px;
  box-sizing: border-box;
}

.companyDescription {
  padding-left: 21px;
  box-sizing: border-box;
  left: 12px;
  position: relative;

  > h1 {
    margin: 0;
  }
  > p {
    margin: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: -10px;
    width: 3px;
    background: $blue-color;
  }
}

#successDetails {
  .listWrapper-list-item:before {
    top: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .companyDescriptionWrapper {
    padding-top: 100px;
  }
}
