$distance: -40px;

#success_stories {
  .services-list {
    width: 90%;
    align-self: center;

    .left {
      left: $distance !important;
    }
    .right {
      right: $distance !important;
    }
    .vacancyPreview {
      width: 100%;
      .imageWrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .summaryText {
    width: 735px;
    margin: 0 auto;
    max-width: 90%;
    padding-top: 120px;
    box-sizing: border-box;
  }
  .successDetails {
    display: flex;
    background: #f5f5f5;
    overflow: hidden;
    align-items: center;
    padding: 1rem;
    margin: 15px 0;
    position: inherit;
  }
}
