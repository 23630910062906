@import "../../../styles/variables";

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  color: $white-color;
  width: 34px;
  height: 32px;
  line-height: 40px;
  text-align: center;
  margin: 0 5px;
  position: relative;
  z-index: 0;
  text-decoration: none;
  transition: color 0.35s ease;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background: $white-color;
    z-index: -1;
    transition: height 0.35s ease;
  }

  &:hover {
    color: $black-color;
    &:after {
      height: 100%;
    }
  }
}
