@import "../../../styles/variables";
@import "../../../styles/classes";

$sS: ".styledSelect";

#{$sS} {
  position: relative;

  &-fullWidth {
    width: 100%;
  }

  &-optionsOpened {
    #{$sS} {
      &-options {
        visibility: visible;
        opacity: 1;
        pointer-events: unset;
      }

      &-selectedWrapper {
        &-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-label {
    @extend .defaultLabel;
    margin: 0 0 6px;
    line-height: normal;
  }

  &-selectedWrapper {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &-arrow,
    &-clear {
      position: absolute;
      right: 9px;
    }
    &-arrow {
      transition: transform 0.2s linear;
      padding: 3px 0;
    }
  }

  &-selected {
    @extend .defaultInput;
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0;
    padding-right: 25px;
    white-space: nowrap;
    height: 40px;
    cursor: inherit;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;

    &:not(:read-only) {
      cursor: text;
    }

    &-img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
      left: 9px;
    }

    &-withImg {
      padding-left: 41px;
    }
  }

  &-options {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $light-grey-color;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s linear;
    max-height: 146px;
    overflow: auto;
    background: $white-color;
    box-shadow: $card-shadow;
    z-index: 2;
  }

  &-helperText {
    @extend .defaultHelperText;
  }
}

.styledSelectError {
  .styledSelect {
    &-label,
    &-helperText {
      color: $red-color;
    }

    &-selected {
      border-color: $red-color;
    }
  }
}

.styledSelectOption {
  color: $warm-grey-color;
  padding: 8px 9px;
  display: flex;
  align-items: center;

  &.disabled {
    &:hover {
      color: $warm-grey-color;
      background: $white-color;
      cursor: default;
    }
  }

  &:hover {
    color: $black-color;
    background: darken($white-color, 3);
    cursor: pointer;
  }

  &-text {
    @extend .h3;
    font: 400 16px "Roboto", sans-serif;
    color: $black-color;
    margin: 0;
  }

  &-img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }
}
