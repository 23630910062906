@import "../../../styles/variables";

.editableList {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &-item {
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(31,28,28,.0784314);
    border-radius: 5px;
    padding: 10px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.active {
      .editableList {
        &-preview {
          border-bottom: 1px solid $light-grey-color;
          margin-bottom: 10px;
          padding-bottom: 5px;
        }
      }
    }
  }

  &-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      margin: 0;
      font-weight: bold;
    }

    &-actions {
      display: flex;

      > button {
        border: transparent;
        background: transparent;
        outline: none;
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 5px;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: $light-grey-color;
        }

        > img {
          object-fit: contain;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
