@import "../../../styles/variables";
@import "../../../styles/classes";

.styledEditor {

  &.error {
    .styledEditor {
      &-label {
        color: $red-color;
      }
      &-editor {
        border-color: $red-color;
      }
      &-helperText {
        color: $red-color;
      }
    }
  }

  &-label {
    @extend .defaultLabel
  }

  &-editor {
    border: 1px solid $dark-grey-color;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-helperText {
    @extend .defaultHelperText
  }
}
