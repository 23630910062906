@import '../../../../../styles/variables.scss';

.message-preview {
  background: $blue-color;
  width: 75%;
  margin-top: 6vh;
  display: flex;
  justify-content: center;
  .name:hover {
    cursor: pointer;
  }
  &-new {
    color: $green-color;
    font-style: italic;
    position: absolute;
    padding: 15px;
    right: 15%;
  }
  .delete-ico {
    width: 30px;
    padding: 12px 30px 12px 0;
    cursor: pointer;
    position: absolute;
    left: 15vw;
  }
}
