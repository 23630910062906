@import '../../../styles/variables';

.sectionTitle {
  margin: 90px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  text-transform: uppercase;

  &-preLine {
    font: 900 24px/1.3 'Roboto', sans-serif;
    padding-bottom: 1px;
    margin: 0;
  }

  &-text {
    margin: 0;
    font: 700 2rem/1.1 'Agency FB', sans-serif;
    color: $black-color;
    padding-bottom: 7px;

    &:first-letter {
      color: $blue-color;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 3.93333rem;
    background: $blue-color;
  }
}
