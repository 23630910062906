@import '../../../../../styles/variables';

.hashItemWrapper {
  > button {
    border: none;
    padding: 0;
    background: transparent;
    display: flex;
  }

  &.active:not(.activeDisabled) {
    > button {
      .navItem {
        &:after {
          z-index: 0;
          opacity: 1;
          transform: scaleX(1);
        }
      }
    }
  }
}

.navItem {
  color: $black-color;
  outline: 0 none;
  text-decoration: none;
  position: relative;
  padding: 0 15px;
  font: 400 20px/50px 'Roboto', sans-serif;
  line-height: 50px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: $blue-color;
    z-index: -1;
    transform: scaleX(0);
    opacity: 0;
    transition: all 0.35s ease 0s;
  }

  &.active,
  &:hover {
    &:after {
      z-index: 0;
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

@media screen and (max-width: 1440px) {
  .navItem {
    padding: 0 15px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1024px) {
  .smallScreenDisabled {
    display: none;
  }
  .navItem:after {
    transform: scaleX(0) !important;
    opacity: 0 !important;
    z-index: -1 !important;
  }
}

@media screen and (max-width: 550px) {
  .navItem {
    padding: 0 7px;
    font-size: 15px;
  }
}
