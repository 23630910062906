.lang-menu {
  align-self: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 30px;

  li {
    display: flex;
    flex-direction: column;
  }

  ul,
  ol {
    list-style: none;
  }

  li ul {
    margin-top: 5px;
  }

  li img {
    transform: scale(1);
    transition: scale 0.3s ease;
    width: 18px;
  }

  li img:hover {
    transform: scale(1.2);
    transition: scale 0.3s ease;
    cursor: pointer;
  }

  /*sub menu*/
  li ul {
    display: none;
    padding: 0;
    cursor: pointer;
  }

  li:hover > ul {
    display: block;
    -webkit-animation: slide-in-top 0.3s linear;
    animation: slide-in-top 0.3s linear;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (max-width: 1024px) {
  .lang-menu {
    width: auto;
  }
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@media screen and (max-width: 500px) {
  .lang-menu {
    right: 20px;
    top: 15px;
    position: absolute;
  }
}
