@import '../../../../../../styles/variables';
@import '../../../../../../styles/classes';

.vacancyForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 300px;
  outline: none;

  &-input {
    @extend .defaultInput;
  }

  &-submitBtn {
    @extend .defaultSubmitBtn;
    width: 100%;
  }

  &-file {
    display: flex;
    flex-direction: column;

    &.error {
      .vacancyForm-file {
        &-input {
          border-color: $red-color;
          color: $red-color;
        }
        &-description {
          color: $red-color;
        }
      }
    }

    &.dragEnter {
      .vacancyForm-file-input {
        &:before {
          opacity: 1;
        }
      }
    }

    &-input {
      cursor: pointer;
      color: $dark-grey-color;
      font: 400 16px 'Roboto', sans-serif;
      text-transform: uppercase;
      border: 2px dashed $dark-grey-color;
      outline: none;
      transition: border-color 0.1s ease;
      position: relative;

      &:before {
        content: 'Drop it!';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: $white-color;
        background: $dark-grey-color;
        opacity: 0;
        transition: opacity 0.1s ease;
      }
    }

    &-placeholder {
      text-align: center;
      padding: 5px 0.66667rem;
      margin: 0;
    }

    &-description {
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .close-ico {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 767px) {
  .vacancyForm {
    max-width: none;
    width: 70vw;
  }
}
