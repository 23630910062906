$black-color: #000000;
$orange-color: #f68d2e;
$blue-color: #3B84E6;
$brown-color: #767676;
$white-color: #ffffff;
$light-grey-color: #f5f5f5;
$dark-grey-color: #506a75;
$grey-color: #999999;
$green-color: #a6f3ac;
$red-color: #b41919;
$warm-grey-color: #a89e99;
$card-shadow: 0 5px 15px rgba(31, 28, 28, 0.08);

:export {
  blueColor: $blue-color;
}
