[class^='i-']:before,
[class*=' i-']:before {
  font-family: 'timelycode', serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.i-location:before {
  content: '\65';
}

.i-facebook:before {
  content: '\64';
}

.i-twitter:before {
  content: '\63';
}

.i-instagram:before {
  content: '\62';
}

.i-linkedin:before {
  content: '\61';
}

.i-phone:before {
  content: '\66';
}

.i-skype:before {
  content: '\67';
}

.i-email:before {
  content: '\68';
}
.i-whatsapp:before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAQAAABKIxwrAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+cGHg0eAsqEmTYAAAIySURBVDjLbdTLbwxQFMfxz4wxNaOjxGtIpQ9WNmpTxYJIIyQk0owikdYrLKxKqh6JWFhJrIg/wCNSCxGqJEUJEkGq6pmQURuaSCSmnU5b2rFolek4Z3fv955z8jvn3IBcm6dKpXKF+iQ99cTXf68DOeh2G0zyUbd+USUWGXHTRV/k2RrtWiXMElZktunCZqrR4r7qiXBCpyYxcXs0e+qVZ5rtNU/MIS9tzY3caYeQWh2GZcd92AtbhNTrsvZvze2ahDRI/YP+8ZQDQpo8VDyKN2oVUysl44qbfk148EOtmFbHRmM/kBDXIeucAmXe52XoEFfjseKgKpPct1GFtGaDPrmRp9oSGz2QtTyo0kcp1QJGDIKePDyoWq8PlgWV6xZRhkIrUGpnflOUifisPGiqjLAIAuqU+ub1f/CIsH7RoD5RQ/rHajzqlyPaQK8uA2N4xpCp0kFJJTKSY8f1Dvpsh9OSTlmrzj0jSMoolWSTh2bZNa522gkxlIiCVfoM2222xxLEtasR1zmu8k83rFcEwk7Iem6uzR6ZD0ddNtmZnMb0euSUgy5J+yFhmlsOE8KgAcRylCi00kqQctw1jaLOQ4HL9pmhzRNXvZ0wkc8lhOzUNTrzIQsUeyZtvx4D5linWpmIAUltWmQ02uakO6P4YiO+ilukzny3XXfBFGFDMoqstktEg7t/drXCWRmFhrzzXZWwpG7p8V1tceHvegcEVFnqnTe+yZprmUoLxaT/9xP8Bp4h4H4dODB1AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA2LTMwVDEzOjI5OjU3KzAwOjAw8lm0fAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wNi0zMFQxMzoyOTo1NyswMDowMIMEDMAAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDYtMzBUMTM6MzA6MDIrMDA6MDDzUvjWAAAAAElFTkSuQmCC');
}

.inTouch-links-item:hover.i-whatsapp:before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAQAAABKIxwrAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAd0SU1FB+cGHg0eF6dZfd0AAAJkSURBVDjLbZTLSxRQFIe/GaepmZws0hrD8FGrNtVGLRdFSBQUhExWEPakFq0qzDKIFq2EVkV/QA/CFhFlFlhZVBAVZvaEYtJNBUHQ6Dhq6tdiJiXtd+Eu7v3OuZdzz+8GAEByKqaaSirIZ4Akz3nGt+xGYHL2L7qTTeTxmV4GiVLKUsa5zWW+TgZgdqyz03YTFhq2wCLnGna+dbb50NosMwkn7LbJmHH32+pz3/jCVg9YbMxjvnZ7LiCXudvdhqy3yzEnNeYrtxlylz2u/4sX22mTIQ+bcrpSHjFkk48tyeKNthuz3pQZr3nb0SkBv6w3ZrsnBYt9ZMK4XeoFZ1rux2kndBm3zqeWBKkmj4dsZgVpWhnmC7eYquVs5hGyKkgln0lRS4BxhgH4Pg0PUks/n6gKUkEvEcqBfFYDZexhusqJ0EdFkNlkCBMBAjRQxg/e/gePEGaQaJABoowwmLtjM6OcoAOAfnoYyuEZRphNOkiSUjIkc8u7OEofuzlLkhbW08ADxoEkGcpI4hYfW+jeiWqnPW1MLDUq4hoHHHOfRT41gXE7rTNu90SVf3vLjRaIGPa0+tKFbvWJixCbveoMz/3zMP0+scWjXjHtLxPO8Y7HJQQMMwTE/qlEPjXUAJDiFDdoJMpFwJle9aDz7PCZ130/pSNfmjDkHnuyPR9iMSW8IM0hvjPEAjZQSzkRhkjSQRsZGtnBGe4BhFjGON+Is5QGFnGXm1xiFmFGyFDAWvYS4TD3s/YLuILzZMhnhA/8pJowSXpJT3i1jUtZeweAgAGqWckH3vEDWUgVlSwhRvp/P8EfSFPHqmqU1sEAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDYtMzBUMTM6MzA6MTcrMDA6MDBLiE6MAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA2LTMwVDEzOjMwOjE3KzAwOjAwOtX2MAAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wNi0zMFQxMzozMDoyMyswMDowMBcA9B8AAAAASUVORK5CYII=');
}
