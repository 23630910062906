@import '../../../styles/variables';
@import '../../../styles/classes';

.styledInput {
  display: flex;
  flex-direction: column;

  &-fullWidth {
    width: 100%;
  }

  &-w75 {
    width: 75%;
  }

  &-label {
    @extend .defaultLabel;
  }

  &-inputWrap {
    display: flex;
    align-items: center;
    position: relative;
  }

  &-icon {
    position: absolute;
    left: 9px;
  }

  &-clear {
    position: absolute;
    right: 9px;
    cursor: pointer;
  }

  &-input {
    @extend .defaultInput;
    margin-bottom: 0;
    flex: 1;
    resize: vertical;

    &-withIcon {
      width: 100%;
      padding-left: 30px;
    }
    &-withClear {
      width: 100%;
      padding-right: 26px;
    }
    .file-name {
      padding: 10px 0;
      position: absolute;
    }
    .remove-file {
      border: none;
      background: none;
      position: relative;
      top: 5px;
      left: -55px;
      cursor: pointer;
      img {
        width: 25px;
      }
    }
  }

  &-textarea {
    padding: 13px 9px;
  }

  &-helperText {
    @extend .defaultHelperText;
  }
}

.styledInputError {
  .styledInput {
    &-label,
    &-helperText {
      color: $red-color;
    }

    &-input {
      border-color: $red-color;
      box-shadow: inset 0 0 0 2px $red-color;
    }
  }
}

input[type='file'] {
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
}
