@import "../../../../../../styles/variables";

.contacts {
  &-addresses, &-contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-footerLinks {
    &-title {
      font-weight: bold;
    }

    &-list {
      display: flex;
      align-items: center;

      .socialLink {
        color: $black-color;
        &:hover {
          color: $white-color;
          &:after {
            background: $black-color;
            height: 100%;
          }
        }
      }
    }
  }

  &-edit {
    display: flex;
    flex-direction: column;
    &-title {
      margin: 0;
    }
    > div:not(:last-child) {
      margin-bottom: 50px;
    }
  }
}
