@import "../../../styles/variables";

#scene {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  -webkit-animation-duration: 0.5s;

  .layer {
    position: absolute;

    &.bigger, &.go {
      div {
        transition: transform 0.35s ease;
      }
    }

    &.bigger div {
      transform: scale(1);
      -webkit-transform: scale(1, 1);
    }

    &.go div {
      transform: scale(0.4);
      -webkit-transform: scale(0.4, 0.4);
    }

    &:first-child {
      left: 8.2% !important;
      top: 1% !important;

      img {
        filter: blur(1px);
      }
    }

    &:nth-child(2) {
      left: 1.3% !important;
      top: 23% !important;
    }

    &:nth-child(3) {
      left: 4.4% !important;
      top: 28% !important;

      img {
        filter: blur(6px);
      }
    }

    &:nth-child(4) {
      left: 11.7% !important;
      top: 44% !important;
    }

    &:nth-child(5) {
      left: -1.2% !important;
      top: 47.5% !important;

      img {
        filter: blur(5px);
      }
    }

    &:nth-child(6) {
      left: 7.7% !important;
      top: 61.3% !important;

      img {
        filter: blur(3px);
      }
    }

    &:nth-child(7) {
      left: 8.1% !important;
      top: 84% !important;
    }

    &:nth-child(8) {
      left: 12.7% !important;
      top: 91% !important;

      img {
        filter: blur(1px);
      }
    }

    &:nth-child(9) {
      left: auto !important;
      right: 10.3%;
      top: 5% !important;

      img {
        filter: blur(4px);
      }
    }

    &:nth-child(10) {
      left: auto !important;
      right: 3.3%;
      top: 19.2% !important;
    }

    &:nth-child(11) {
      left: auto !important;
      right: 10.5%;
      top: 34.2% !important;
    }

    &:nth-child(12) {
      left: auto !important;
      right: -0.7%;
      top: 35% !important;

      img {
        filter: blur(3px);
      }
    }

    &:nth-child(13) {
      left: auto !important;
      right: 4.7%;
      top: 53% !important;

      img {
        filter: blur(6px);
      }
    }

    &:nth-child(14) {
      left: auto !important;
      right: 1.5%;
      top: 74% !important;
    }

    &:last-child {
      left: auto !important;
      right: 7.7%;
      top: 90% !important;

      img {
        filter: blur(1px);
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  #scene .layer {
    &:first-child {
      left: 6.5% !important;
    }

    &:nth-child(3) {
      left: 2.7% !important;
    }

    &:nth-child(4) {
      left: 10% !important;
    }

    &:nth-child(5) {
      left: -3.5% !important;
    }

    &:nth-child(6) {
      left: 6% !important;
    }

    &:nth-child(7) {
      left: 6.4% !important;
    }

    &:nth-child(8) {
      left: 11% !important;
    }

    &:nth-child(9) {
      right: 8.3%;
    }

    &:nth-child(10) {
      right: 1.3%;
    }

    &:nth-child(11) {
      right: 8.5%;
    }

    &:nth-child(12) {
      right: -1.7%;
    }

    &:nth-child(13) {
      right: 2.7%;
    }

    &:nth-child(14) {
      right: -0.5%;
    }

    &:last-child {
      right: 5.7%;
    }
  }
}

@media screen and (max-width: 1580px) {
  #scene .layer {
    &:first-child {
      left: 4.5% !important;
    }

    &:nth-child(2) {
      left: 0.6% !important;
    }

    &:nth-child(3) {
      left: 1.7% !important;
    }

    &:nth-child(4) {
      left: 7% !important;
    }

    &:nth-child(5) {
      left: -3.5% !important;
    }

    &:nth-child(6) {
      left: 4% !important;
    }

    &:nth-child(7) {
      left: 4.4% !important;
    }

    &:nth-child(8) {
      left: 8% !important;
    }

    &:nth-child(9) {
      right: 5.3%;
    }

    &:nth-child(10) {
      right: 1.3%;
    }

    &:nth-child(11) {
      right: 6.5%;
    }

    &:nth-child(12) {
      right: -2.7%;
    }

    &:nth-child(13) {
      right: 1.7%;
    }

    &:nth-child(14) {
      right: -1.5%;
    }

    &:last-child {
      right: 3.7%;
    }
  }
}

@media screen and (max-width: 1359px) {
  #scene {
    display: none;
  }
}
