@import "../../../../styles/variables";
@import "../../../../styles/mixins";

$lf: '.lookingFor';
$vacancyDetailsWrapper: '.vacancyDetailsWrapper';
$vacancyDetails: '.vacancyDetails';
$vacancyPreview: '.vacancyPreview';
$vacanciesListItem: #{$lf}-vacanciesList-item;

#{$lf} {
  &-vacanciesList {
    display: block;
    text-align: center;
    @include lookingForList($vacancyPreview, $vacancyDetailsWrapper, 4, 20px);
  }
}

@media screen and (max-width: 1024px){
  #{$lf} {
    &-vacanciesList {
      @include lookingForList($vacancyPreview, $vacancyDetailsWrapper, 3, 20px);
    }
  }
}


@media screen and (max-width: 768px){
  #{$lf} {
    &-vacanciesList {
      @include lookingForList($vacancyPreview, $vacancyDetailsWrapper, 2, 20px);
    }
  }
}

@media screen and (max-width: 500px){
  #{$lf} {
    &-vacanciesList {
      @include lookingForList($vacancyPreview, $vacancyDetailsWrapper, 1, 20px);
    }
  }
}
