@import "../../../styles/variables";

$iu: ".imageUpload";

#{$iu} {
  overflow: hidden;
  position: relative;
  background: $light-grey-color;
  width: 100%;
  height: 100%;

  &-uploaded {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    height: 100%;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-options {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &-select {
    z-index: 2;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    background-repeat: no-repeat;
    background-position: center;

    &-icon {
      position: absolute !important;
      bottom: 0;
      right: 0;
    }
  }

  &-circle {
    border-radius: 50%;

    &:hover {
      #{$iu} {
        &-uploaded {
          &:before {
            content: " ";
            width: 100%;
            height: 100%;
            background-color: rgba($black-color, 0.9);
            position: absolute;
            transition: background-color 0.1s linear;
          }
        }

        &-options {
          opacity: 1;
        }
      }
    }

    #{$iu} {
      &-select {
        cursor: pointer;
      }

      &-uploaded {
        &:before {
          transition: background-color 0.1s linear;
        }
      }

      &-options {
        opacity: 0;
        align-items: center;
        transition: opacity 0.1s linear;
        display: flex;
        bottom: unset;
        right: unset;

        &-add,
        &-remove {
          cursor: pointer;
        }

        &-add {
          margin-right: 16px;
        }
      }
    }
  }
}
