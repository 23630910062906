@import '../../../../styles/variables';
@import '../../../../styles/classes';

@mixin headerOnTop {
  .header {
    &-smallLogo {
      transform: translateX(0);
      opacity: 1;
    }

    &-nav {
      transform: translateX(0);
      background: $white-color;
      box-shadow: 0 0 8px 8px rgba(255, 255, 255, 1);
    }
  }
}

@mixin headerFixed {
  @include headerOnTop;
  .header {
    &-nav {
      z-index: 2;
      position: fixed;
    }
  }
}

@mixin headerDefaultFixed {
  @include headerFixed;

  .header {
    height: 50px;
    &-bigLogo {
      display: none;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 244px;
  z-index: 4;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  &:after {
    background: transparent;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: none;
    z-index: 4;
  }

  &.onTop {
    @include headerOnTop;
  }

  &.fixed {
    @include headerFixed;
  }

  &.defaultFixed {
    @include headerDefaultFixed;
  }

  &-bigLogo {
    padding: 34px 0 45px;
    height: 194px;
    box-sizing: border-box;
  }

  &-smallLogo {
    display: flex;
    align-items: center;
    height: 50px;
    transform: translateX(-204px);
    overflow: hidden;
    transform-origin: left;
    transition: transform 0.7s cubic-bezier(0.44, 0.2, 0.14, 1) 0s, opacity 0.35s ease;
    opacity: 0;
    cursor: pointer;

    &-img {
      width: 55px;
      height: 100%;
      padding: 3px 0;
      object-fit: contain;
      box-sizing: border-box;
    }

    &-title {
      margin-left: 3px;
      font: 400 1.46667rem/1 'Agency FB', sans-serif;
      color: $blue-color;

      span {
        color: $blue-color;
      }

      small {
        font-size: 1.46667rem;
        color: $dark-grey-color;
      }
    }
  }

  &-navItemsWrapper {
    display: flex;
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: background 0.2s ease, transform 0.7s ease 0s;
    background: transparent;
    transform: translateX(-102px);
    overflow: hidden;
    min-height: 50px;
  }
}

.shake-left_start {
  -webkit-animation: shake-left 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-left 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
.shake-left_end {
  -webkit-animation: none;
  animation: none;
}

@media screen and (max-width: 1024px) {
  @include headerDefaultFixed;
  .header {
    &-nav {
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    &-nav {
      padding: 0 1rem;
    }
    &-smallLogo {
      &-img {
        width: 38px;
      }
    }
    &-navItemsWrapper {
      flex-wrap: wrap;
      padding-right: 20px;
    }
  }
}

@-webkit-keyframes shake-left {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  90% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
}
@keyframes shake-left {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }

  90% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
}
