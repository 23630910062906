.listEditComponent {
  display: flex;

  .imageUpload {
    width: 20%;
    margin-right: 15px;
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .button-wrapper{
    margin: 25px 5px 0 15px;
    .contain {
      object-fit: contain;
      width: 100%;
      height: auto;
      padding-bottom: 10px;
    }
  }
}
