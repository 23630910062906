@import 'variables';

.container {
  width: 1140px;
  max-width: 94%;
  margin: 0 auto;
}

.section {
  display: flex;
  flex-direction: column;
}

.h3 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: $warm-grey-color;
}

.h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: $warm-grey-color;
}

.jc_end {
  justify-content: end;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.ai-center {
  align-items: center;
}

.full-width {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.underline {
  border-bottom: 1px solid $brown-color;
}

.pointer {
  cursor: pointer;
}

.button-action {
  border: transparent;
  background: transparent;
  outline: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.2s ease-in-out;
}

.defaultInput {
  outline: none;
  display: block;
  height: 38px;
  font: 400 16px 'Roboto', sans-serif;
  color: $black-color;
  padding: 0 10px;
  border-radius: 0;
  appearance: none;
  background: $white-color;
  border: none;
  margin-bottom: 0.66667rem;
  box-shadow: inset 0 0 0 2px $dark-grey-color;
  transition: all 0.35s ease;

  &.error {
    box-shadow: inset 0 0 0 2px $red-color;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px #bfbfbf;
  }
}

.defaultSubmitBtn {
  background: white;
  display: inline-block;
  min-width: 4.06667rem;
  font: 400 16px/34px 'Roboto', sans-serif;
  color: $blue-color;
  text-transform: uppercase;
  border: 2px solid $blue-color;
  text-align: center;
  padding: 0 0.66667rem;
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  width: max-content;
  outline: none;
  transition: all 0.35s ease;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background: $blue-color;
    z-index: -1;
    transition: all 0.35s ease;
  }

  &:hover:not(:disabled) {
    color: $white-color;

    &:after {
      height: 100%;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.defaultHelperText {
  font-size: 12px;
  margin: 8px 0 0;
  min-height: 1em;
  text-align: left;
  font-weight: 300;
  line-height: 1em;

  &:first-letter {
    text-transform: capitalize;
  }
}

.defaultLabel {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #a89e99;
  line-height: normal;
  margin: 0 0 6px;

  &-required {
    &:after {
      content: '*';
      font-size: 15px;
      color: $red-color;
    }
  }
}

@media screen and (max-width: 1440px) {
  .container {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
