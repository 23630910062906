@import "../../../../styles/variables";

.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  margin-top: 90px;
  padding: 15px 0 17px;
  color: $white-color;
  background: $black-color;
  display: flex;
  justify-content: center;

  &-links, &-desctiption {
    flex: 1;
  }

  &-links {
    display: flex;
    align-items: center;

    &-title {
      margin: 0;
      font-style: normal;
      text-transform: uppercase;
      color: $white-color;
      font: 500 18px/1.4 "Roboto", sans-serif;
      padding-right: 6%;
      white-space: nowrap;
    }
  }

  &-description {
    margin: 0;
    font: 300 13px/1.2 "Roboto", sans-serif;
  }
}


@media screen and (max-width: 768px){
  .footerWrapper {
    flex-direction: column;
  }
  .footer {
    &-links {
      margin-bottom: 10px;
      &-item {
        width: 24px;
        height: 28px;
      }
    }
    &-description {
      font-size: 12px;
    }
  }
}
