@mixin lookingForList($previewElem, $detailsElem, $itemsPerRow, $gap) {
  margin-right: -$gap;
  #{$previewElem} {
    width: calc((100% / #{$itemsPerRow}) - #{$gap});
    margin: #{$gap / 2} #{$gap} #{$gap / 2} 0;
  }
  #{$detailsElem} {
    width: calc(100% - #{$gap});
  }
}
