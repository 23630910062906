.arrow {
  display: inline-block;
  line-height: 1;
  position: absolute;
  top: 37%;
  cursor: pointer;
  z-index: 1;
  font-size: 40px;

  &:after {
    content: '\69';
    font-family: 'timelycode', serif;
    line-height: 1;
  }

  &.top {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(-90deg);
  }
}


@media screen and (max-width: 1280px) {
  .arrow, {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .arrow, {
    font-size: 24px;
  }
}
