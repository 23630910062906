@import "../../../../../styles/variables";

.vacancyDetailsWrapper {
  float: left;
  transition: height 0.7s ease;
}

.vacancyDetails {
  display: flex;
  background: $light-grey-color;
  overflow: hidden;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
}

@media (max-width:767px) {
  .vacancyDetails{
    flex-direction: column;
    &-description{
      width: 85vw;
    }
  }
}
