@import "../../../styles/variables";

.editToggle {
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;

  &-editBtn, &-actions {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-editBtn {
    padding: 0 15px;
    border: 2px solid;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $blue-color;
      border-color: $blue-color;
    }
  }

  &-actions {
    &-action {
      width: 20px;
      height: auto;
      padding: 5px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: $light-grey-color;
      }
    }
  }
}
