@import "../../../styles/variables";

.listWrapper {
  display: flex;
  flex-direction: column;
  text-align: left;

  &-title {
    padding: 5px 0 5px 10px;
    border-left: 2px solid $blue-color;
    margin: 0 0 0.66667rem;
    font: 500 18px/1.4 "Roboto", sans-serif;
    color: $black-color;
  }

  &-list {
    padding: 0 0 0.83333rem 1rem;
    list-style: none;
    margin: 0;

    &-item {
      font-size: 16px;
      padding: 4px 0 4px 10px;
      position: relative;

      > p, > a {
        margin: 0;
        text-decoration: none;
        outline: none;
      }

      > a {
        color: $black-color;
        transition: color 0.35s ease;
        &:hover {
          color: $blue-color;
        }
      }

      &:before {
        content: '\69';
        font-family: 'timelycode', serif;
        position: absolute;
        top: 50%;
        left: -10px;
        font-size: 12px;
        line-height: 1;
        color: $blue-color;
        transform: rotate(-90deg) translateX(50%);
      }
    }
  }
}
